<template>
  <div class="min-h-screen bg-green-500">
    <div class="absolute h-[280px] w-full bg-green-300" />

    <TnHeader />

    <div class="max-w-app relative mx-auto px-0 py-6 sm:px-6 lg:px-0">
      <slot />
    </div>

    <TnFooter class="pb-[150px] sm:pb-6" />
  </div>
</template>
